import React, {useState} from 'react';
import {EmailAndPasswordLogin, linkAccountsWithEmailAndPassReauthentication} from 'app/services/firebase';
import {PasswordFormReAuth} from 'app/components/reauthentication/PasswordFormReAuth';

const PasswordFormReAuthToLinkAccounts = ({setShowLoading, onError, redirectUrl, email}) => {
  const [disableButton, setDisableButton] = useState(false);

  const onSubmit = (password) => {
    // function is only called, when inputs are valid
    // Reset error in case form is submitted a second time
    onError(null, '');
    setDisableButton(true);
    setShowLoading(true);
    EmailAndPasswordLogin(email, password)
      .then((result) => linkAccountsWithEmailAndPassReauthentication(result.user, redirectUrl))
      .catch((error) => {
        setDisableButton(false);
        setShowLoading(false);
        onError(error, email);
      });
  };

  const infoText = 'Um das Verbinden der Konten abzuschließen, musst du dein Passwort erneut eingeben.';
  return (
    <PasswordFormReAuth
      email={email}
      handleButtonAction={(password) => onSubmit(password)}
      infoText={infoText}
      disableButton={disableButton}
    />
  );
};

export {PasswordFormReAuthToLinkAccounts};
