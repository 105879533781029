import React from 'react';
import styled from 'styled-components';

const AuthProviderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 60%;
  align-items: center;
  justify-content: space-evenly;
  height: 13rem;
  min-height: 13rem;
`;

export {AuthProviderContainer, AuthButtonContainer};
