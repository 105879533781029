import React, {useState, useEffect} from 'react';
import {Button, COLORS} from '@fupa/fupa-uikit';
import {GoogleLogin, FacebookLogin, AppleLogin} from 'app/services/firebase';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectRedirectUrl,
  selectReAuthenticationNeed,
  selectJWTCookieName,
  selectCurrentUser,
} from 'app/hooks/reduxCreateSelectorHooks';
import {LoadingSkeleton} from 'app/components/loading/LoadingSkeleton';
import {LegalNote} from 'app/components/legal/LegalNote';
import {MainLayout} from 'app/components/MainLayout';
import {AuthButtonContainer, AuthProviderContainer} from 'app/components/authProvider/AuthProviderContainer';
import {SeparatorWithText} from 'app/components/SeparatorWithText';
import {FederatedProviderButtons} from 'app/components/authProvider/FederatedProviderButtons';
import {AuthHint} from 'app/components/authProvider/AuthHint';
import {PasswordFormReAuthToLinkAccounts} from 'app/components/reauthentication/PasswordFormReAuthToLinkAccounts';
import {string} from 'prop-types';
import {useLocation} from 'react-router';
import {createSelector} from 'reselect';
import {errorAuth} from 'app/actions/AuthPageErrorActions';
import {ErrorAlert} from 'app/components/error/ErrorAlert';
import {LoadingOverlay} from 'app/components/loading/LoadingOverlay';
import {LinkOnComponent} from 'app/styles/link';
import {Mail} from '@mui/icons-material';
import {TeaserMainLayout} from 'app/components/TeaserMainLayout';

const selectAuthPageError = createSelector(
  state => state.authPageError,
  authPageError => authPageError
);

const AuthPage = props => {
  const {
    type,
    headline,
    subtitle,
    emailButtonLabel,
    emailButtonLinkTo,
    hintText,
    hintLinkedText,
    hintLinkTo,
    openLinksBlank,
  } = props;
  const redirectUrl = useSelector(selectRedirectUrl);
  const jwtCookieName = useSelector(selectJWTCookieName);
  const emailForPasswordFormReAuth = useSelector(selectReAuthenticationNeed);
  const {districtSlug, authRedirect} = useSelector(selectCurrentUser);

  const authPageError = useSelector(selectAuthPageError);
  // FixMe: GoogleLogin - `app` as workaround to prevent older app versions to show google login
  const [isLoadingEmailAuth, setIsLoadingEmailAuth] = useState(false);
  const [error, setError] = useState(false);
  const [emailWithError, setEmailWithError] = useState('');
  const dispatch = useDispatch();
  const {search} = useLocation();

  // Start handle error for federated provider
  useEffect(() => {
    if (authPageError) {
      setError(authPageError);
    }
  }, [authPageError]);

  useEffect(() => {
    return () => dispatch(errorAuth(null));
  }, []);
  // End handle error for federated provider

  const handleLoadingEmailAuth = value => {
    setIsLoadingEmailAuth(value);
  };

  const handleError = (error, email) => {
    setEmailWithError(email);
    setError(error);
  };

  const handleGoogle = () => {
    if (type === 'signup') {
      // TODO: Fire GA Event for 'fupa_sign_up_start'
    }
    GoogleLogin(redirectUrl, dispatch, jwtCookieName, districtSlug);
  };

  const handleFacebook = () => {
    if (type === 'signup') {
      // TODO: Fire GA Event for 'fupa_sign_up_start'
    }
    FacebookLogin(redirectUrl, dispatch, jwtCookieName, districtSlug);
  };

  const handleApple = () => {
    if (type === 'signup') {
      // TODO: Fire GA Event for 'fupa_sign_up_start'
    }
    AppleLogin(redirectUrl, dispatch, jwtCookieName, districtSlug);
  };

  let title = headline;
  let authContent = (
    <AuthProviderContainer>
      <AuthButtonContainer>
        <LinkOnComponent tabIndex='-1' to={{pathname: emailButtonLinkTo, search}}>
          <Button tabIndex='1' label={emailButtonLabel} icon={<Mail style={{color: COLORS.white}} />} fullwidth />
        </LinkOnComponent>
        <SeparatorWithText text='Oder' />
        <FederatedProviderButtons onClickGoogle={handleGoogle} onClickFb={handleFacebook} onClickApple={handleApple} />
      </AuthButtonContainer>
      <AuthHint text={hintText} linkedText={hintLinkedText} linkTo={hintLinkTo} />
    </AuthProviderContainer>
  );

  if (emailForPasswordFormReAuth) {
    title = 'Bitte gib dein Passwort ein';
    authContent = (
      <PasswordFormReAuthToLinkAccounts
        redirectUrl={redirectUrl}
        email={emailForPasswordFormReAuth}
        setShowLoading={value => handleLoadingEmailAuth(value)}
        onError={(error, email) => handleError(error, email)}
      />
    );
  }

  const showError = error ? <ErrorAlert error={error} email={emailWithError} /> : null;

  if (authRedirect) {
    return <LoadingSkeleton />;
  } else {
    const content = openLinksBlank ? (
      <TeaserMainLayout title={title} subtitle={subtitle}>
        {authContent}
        {showError}
      </TeaserMainLayout>
    ) : (
      <MainLayout title={title} subtitle={subtitle}>
        {authContent}
        {showError}
      </MainLayout>
    );

    return (
      <>
        <LoadingOverlay open={isLoadingEmailAuth} />
        {content}
        <LegalNote />
      </>
    );
  }
};

export default AuthPage;

AuthPage.propTypes = {
  headline: string.isRequired,
  subtitle: string,
  emailButtonLabel: string.isRequired,
  emailButtonLinkTo: string.isRequired,
  hintText: string.isRequired,
  hintLinkedText: string.isRequired,
  hintLinkTo: string.isRequired,
};
