import {COLORS, SIZES} from '@fupa/fupa-uikit';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNative} from 'app/contexts/NativeContext';

const FederatedProviderPlaceholder = styled.div`
  visibility: hidden;
  height: ${SIZES['36']};
`;

const FederatedButtonWithLabel = styled.button`
  margin: 0.25rem;
  padding-left: 0;
  padding-right: 0.75rem;
  background-color: ${props => props.color || COLORS.white};
  border: ${props => (!props.color ? `1px solid ${COLORS.grey}` : 'none')};
  border-radius: 0.25rem;
  display: flex;
  height: 2.25rem;
  width: 14.5rem;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  justify-items: center;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

const Icon = styled.img`
  margin: ${props => props.margin};
`;

const Label = styled.span`
  margin-left: ${props => props.labelmargin || '0.75rem'};
  display: flex;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1rem;
  color: ${props => props.color || COLORS.black};
  text-overflow: ellipsis;
`;

const FederatedProviderButtons = ({onClickFb, onClickGoogle, onClickApple}) => {
  const [show, setShow] = useState({facebookLogin: true, googleLogin: true, appleLogin: false});
  const {platform, isFlutter} = useNative();
  const isFlutterAndroid = platform === 'android' && isFlutter;

  useEffect(() => {
    setShow(prevState => ({...prevState, appleLogin: !isFlutterAndroid}));
  }, []);

  const FacebookLogin = show.facebookLogin ? (
    <FederatedButtonWithLabel tabIndex='1' onClick={onClickFb} color='#1877F2'>
      <Icon margin={'0 0 0 0.75rem'} src='facebook-white.svg' width={20} height={20} alt='facebook' />
      <Label color={COLORS.white}>Mit Facebook fortfahren</Label>
    </FederatedButtonWithLabel>
  ) : (
    <FederatedProviderPlaceholder />
  );

  const GoogleLogin = show.googleLogin ? (
    <FederatedButtonWithLabel tabIndex='1' onClick={onClickGoogle}>
      <Icon margin={'0 0 0 0.75rem'} src='google_logo.svg' width={20} height={20} alt='google' />
      <Label color={'rgba(0, 0, 0, .54)'}>Mit Google fortfahren</Label>
    </FederatedButtonWithLabel>
  ) : (
    <FederatedProviderPlaceholder />
  );

  const AppleLogin = show.appleLogin ? (
    <FederatedButtonWithLabel tabIndex='1' onClick={onClickApple} color='black'>
      <Icon margin={'0 0 0 0.75rem'} src='apple-logo-white.svg' width={20} height={20} alt='apple' />
      <Label color={COLORS.white}>Mit Apple fortfahren</Label>
    </FederatedButtonWithLabel>
  ) : (
    <FederatedProviderPlaceholder />
  );

  return (
    <>
      {FacebookLogin}
      {GoogleLogin}
      {AppleLogin}
    </>
  );
};

export {FederatedProviderButtons};
