import React from 'react';
import styled from 'styled-components';
import {COLORS, Typography} from '@fupa/fupa-uikit';

const Separator = styled.div`
  display: flex;
  margin: 0.5rem 0;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.span`
  display: inline-flex;
  width: 4rem;
  margin: 0;
  height: 1px;
  background-color: ${COLORS.grey};
`;

const Caption = styled(Typography)`
  text-transform: uppercase;
  margin: 0 0.5rem;
`;

const SeparatorWithText = ({text}) => {
  return (
    <Separator>
      <Line />
      <Caption variant='caption1'>{text}</Caption>
      <Line />
    </Separator>
  );
};

export {SeparatorWithText};
