import React from 'react';
import {COLORS} from '@fupa/fupa-uikit';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.25rem;
  @media screen and (min-width: 500px) {
    padding: 0 2.5rem;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Separator = styled.div`
  display: flex;
  margin: 0.5rem 0;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.span`
  display: inline-flex;
  width: 4rem;
  margin: 0;
  height: 1px;
  background-color: ${COLORS.lighterGrey};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 60%;
  align-items: center;
  justify-content: space-evenly;
  max-height: 13rem;
`;

const SkeletonContainer = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  background: ${COLORS.lighterGrey};
  position: relative;
  overflow: hidden;
  border-radius: 1rem;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    outline: 1px solid transparent;
    background: linear-gradient(
      110deg,
      ${COLORS.lighterGrey} 0%,
      ${COLORS.lightestGrey} 45%,
      ${COLORS.lighterGrey} 90%
    );
    animation: progress 1.25s ease-in-out infinite;
  }

  @keyframes progress {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const LoadingSkeleton = () => {
  return (
    <Wrapper>
      <SkeletonContainer width='8rem' height='3rem' margin='1.25rem 0 0.25rem 0' />
      <SkeletonContainer width='9.5rem' height='3rem' margin='0 0 1.25rem 0' />
      <SignUpContainer>
        <ButtonWrapper>
          <SkeletonContainer width='13.5rem' height='2.25rem' />
          <Separator>
            <Line />
            <SkeletonContainer width='2rem' height='0.75rem' margin='0 0.5rem' />
            <Line />
          </Separator>
          <SkeletonContainer width='13.5rem' height='2.25rem' margin='0 0 0.25rem 0' />
          <SkeletonContainer width='13.5rem' height='2.25rem' />
        </ButtonWrapper>
        <SkeletonContainer width='18.5rem' height='1rem' margin='1rem 0' />
      </SignUpContainer>
      <Container>
        <SkeletonContainer width='13rem' height='0.75rem' margin='0 0 0.25rem 0' />
        <SkeletonContainer width='15rem' height='0.75rem' margin='0 0 0.25rem 0' />
        <SkeletonContainer width='17rem' height='0.75rem' margin='0 0 0.25rem 0' />
      </Container>
    </Wrapper>
  );
};

export {LoadingSkeleton};
